<template>
  <transition name="fade">
    <div
      v-if="isShowing"
      class="modal fade show"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-backdrop fade show" @click="dismiss"></div>
      <div
        class="modal-dialog"
        :class="{ 'modal-xl': size === 'xl' }"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ title }}</h5>
            <button
              type="button"
              class="close"
              @click="dismiss"
              aria-label="Close"
            >
              <span aria-hidden="true">x</span>
            </button>
          </div>
          <form @submit.prevent="submit">
            <div class="modal-body">
              <slot />
            </div>
            <div v-if="!noFooter" class="modal-footer">
              <slot name="footer">
                <button
                  type="button"
                  class="btn btn-secondary btn-outline"
                  @click="dismiss"
                >
                  {{ $t('generic-str.cancel') }}
                </button>
                <button type="submit" class="btn btn-primary">
                  {{ $t('generic-str.create') }}
                </button>
              </slot>
            </div>
          </form>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    title: String,
    size: String,
    noFooter: Boolean,
  },
  data() {
    return {
      isShowing: false,
      loading: true,
    };
  },
  methods: {
    toggle() {
      this.isShowing = !this.isShowing;
      if (this.isShowing) {
        document.body.classList.add('modal-open');
      }
    },
    dismiss() {
      this.isShowing = false;
      document.body.classList.remove('modal-open');
    },
    submit() {
      this.$emit('submit');
      this.dismiss();
    },
  },
};
</script>

<style scoped lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.modal.show {
  display: block;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  .modal-dialog {
    transition: -webkit-transform 0.1s ease-out;
    transition: transform 0.1s ease-out;
    transition: transform 0.1s ease-out, -webkit-transform 0.3s ease-out;
    -webkit-transform: translate(0, -50px);
    transform: translate(0, -50px);
  }
}
.modal-dialog {
  z-index: 9999;
}
// .modal-body {
//   max-height: 80vh;
//   overflow: auto;
// }
</style>
