<template>
  <div class="custom-checkbox">
    <div class="d-flex align-items-center">
      <div class="p-1 flex-grow-1">
        <div class="control-group opt-2">
          <label class="control control-checkbox" :for="`${val}Checkbox`">
            <input
              type="checkbox"
              class="custom-control-input"
              :id="`${val}Checkbox`"
              :value="val"
              v-model="model"
            />
            <div class="control_indicator"></div>
          </label>
        </div>
      </div>
      <div class="p-1 label">{{label}}</div>
    </div>
  </div>

  <!-- <span class="to-left check-info">
    <div class="control-group opt-2 to-left" style="margin-right: 5px">
    </div>
    {{ label }}</span
  > -->
</template>

<script>
export default {
  name: 'Checkbox',
  props: {
    label: {
      type: String,
      default: '',
    },
    val: {
      type: String,
      default: '',
    },
    value: Array,
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>

<style scoped lang="scss">
.label {
  white-space: nowrap;
}

.custom-checkbox {
  display: inline-block;
}
</style>
